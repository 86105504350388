<template>
  <div class="container">
    <el-button type="primary" @click="getQrCode('community')"
      >山水社区问卷</el-button
    >

     <el-button type="primary" @click="getQrCode('classList')"
      >拼班列表</el-button
    >
    <el-dialog title="二维码" :visible.sync="qrdialogVisible">
      <div class="flex jc-c">
        <img :src="codeimg" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrdialogVisible = false">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "PageShare",

  data() {
    return {
      qrdialogVisible: false,
      exportLink: "",
      downloadFilename: "",
      codeimg: "",
    };
  },

  mounted() {},

  methods: {

    getQrCode(v) {
        this.qrdialogVisible = true
      let data = {
        scene: "share=admin",
      };

        switch (v) {
            case 'community':
                data.page = "pages/others/comQusetion"
                break;
            case 'classList':
                data.page = "pages/index/mechanismClass/classList"
                break;
            default:
                break;
        }

      this.$axios({
        url: "/user/user/generateQRCodeSportPic",
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.codeimg = res.data.data.img;
        } else {
          this.reindex++;
          if (this.reindex > 3) {
            return;
          } else {
            let id = setTimeout(() => {
              this.getQrCode();
              clearTimeout(id);
            }, 1000);
          }
        }
      });
    },
    downloadImg() {
      let exportLink = "https://img.curiousmore.com/1669253666474.png";
      var x = new XMLHttpRequest();
      x.open("GET", exportLink, true);
      x.responseType = "blob";
      x.onload = function (e) {
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = "二维码";
        a.click();
      };
      x.send();

    //   this.downloadFilename = "二维码";
    },
  },
};
</script>

<style lang="less" scoped>
a{
    color: aqua;
}
</style>